import { ref } from 'vue';
import { login } from '../../api/list';
import { hi } from '../../api/list';
import { setToken } from '../../utils/auth';
import { router } from '../../router';
export default {
  setup() {
    const username = ref('');
    const password = ref('');

    const onSubmit = values => {
      console.log('submit', values);
      console.log(values.username);
      login({
        loginName: values.username,
        pwd: values.password
      }).then(res => {
        console.log(res);
        setToken(res.data); // location.href = '#/index'

        router.push({
          path: '/index'
        });
      }); // hi("hi").then((res)=>{
      //   console.log(res);
      // })
    };

    return {
      username,
      password,
      onSubmit
    };
  }

};